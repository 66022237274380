import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { formatMoney } from "../../../services/FormatService/FormatText";
import TransactionsItemIcon from "./TransactionsItemIcon";

const TransactionsItem = ({
  item,
  onClick,
  onDelete,
  isActiveMenu,
  openMenu,
  closeMenu,
  setIsCopy,
}) => {
  const isIncome = item.amount > 0;
  const [slideDirection, setSlideDirection] = useState(null); // null, "left", or "right"
  const [isSliding, setIsSliding] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const handleItemClick = (e) => {
    const itemWidth = e.currentTarget.offsetWidth;
    const clickX = e.clientX - e.currentTarget.getBoundingClientRect().left;

    if (isSliding) {
      resetSlide();
      return;
    }

    if (clickX < itemWidth / 4) {
      setSlideDirection("right");
    } else if (clickX > (3 * itemWidth) / 4) {
      setSlideDirection("left");
    } else {
      onClick(item);
      return;
    }

    setIsSliding(true);
  };

  const handleContextMenu = (e) => {
    resetSlide();
    e.preventDefault();
    setMenuPosition({ x: e.pageX, y: e.pageY });
    openMenu();
  };

  const handleCopy = () => {
    setIsCopy(true);
    onClick(item);
    resetSlide();
    closeMenu();
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this transaction?"
    );
    if (isConfirmed) {
      onDelete(item);
      resetSlide();
      closeMenu();
    }
  };

  const resetSlide = () => {
    setIsSliding(false);
    setTimeout(() => setSlideDirection(null), 250);
  };

  useEffect(() => {
    if (isActiveMenu) {
      const closeMenuHandler = (e) => {
        if (
          e.type === "click" ||
          (e.type === "keydown" && e.key === "Escape")
        ) {
          closeMenu();
        }
      };

      document.addEventListener("click", closeMenuHandler);
      document.addEventListener("keydown", closeMenuHandler);

      return () => {
        document.removeEventListener("click", closeMenuHandler);
        document.removeEventListener("keydown", closeMenuHandler);
      };
    }
  }, [isActiveMenu, closeMenu]);

  const contextMenu = (
    <div
      className="absolute bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-300 rounded-lg shadow-lg z-50"
      style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}
    >
      <button
        onClick={() => {
          onClick(item);
          closeMenu();
        }}
        className="block py-2.5 px-6 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700 rounded-t-lg"
      >
        Edit
      </button>

      <button
        onClick={() => {
          handleCopy(item);
          closeMenu();
        }}
        className="block py-2.5 px-6 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700"
      >
        Copy
      </button>

      <button
        onClick={() => {
          handleDelete(item);
          closeMenu();
        }}
        className="block py-2.5 px-6 text-center text-red-500 w-full hover:bg-gray-100 dark:hover:bg-gray-700 rounded-b-lg"
      >
        Delete
      </button>
    </div>
  );

  return (
    <>
      <div className="relative mb-4 mx-2 select-none overflow-x-hidden">
        {/* Underlying Delete and Copy options */}
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg transition-opacity duration-300 ${
            isSliding ? "opacity-100" : "opacity-0"
          }`}
        >
          {/* Copy Button */}
          <button
            className="text-blue-600 dark:text-blue-400 font-semibold py-5 pr-[15%]"
            onClick={handleCopy}
          >
            Copy
          </button>

          {/* Delete Button */}
          <button
            className="text-red-500 dark:text-red-400 font-semibold py-5 pl-[15%]"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>

        {/* Transaction Item */}
        <div
          className={`relative flex items-center justify-between p-4 rounded-lg shadow-md cursor-pointer transition-transform transform ${
            isIncome
              ? `bg-green-50 hover:bg-green-100 border-l-4 border-green-600`
              : `bg-red-50 hover:bg-red-100 border-l-4 border-red-500`
          } ${
            isSliding && slideDirection === "left"
              ? "translate-x-[-25%] md:translate-x-[-10%]"
              : ""
          } ${
            isSliding && slideDirection === "right"
              ? "translate-x-[25%] md:translate-x-[10%]"
              : ""
          } ${
            slideDirection !== null
              ? isIncome
                ? "dark:bg-green-900 dark:hover:bg-green-800"
                : "dark:bg-red-950 dark:hover:bg-red-900"
              : isIncome
              ? "dark:bg-green-900/40 dark:hover:bg-green-800/50"
              : "dark:bg-red-950/40 dark:hover:bg-red-900/40"
          }`}
          onClick={handleItemClick}
          onContextMenu={handleContextMenu} // Context menu on right-click
        >
          <div className="flex items-center">
            <div
              className={`p-2 rounded-full ${
                isIncome
                  ? "bg-green-200 text-green-800"
                  : "bg-red-200 text-red-800"
              }`}
            >
              <TransactionsItemIcon item={item} isIncome={isIncome} />
            </div>

            <div className="ml-4">
              <p className="text-lg font-semibold text-gray-800 dark:text-gray-300">
                {item.transactionCategory}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {item.description}
              </p>
              <p className="text-sm text-gray-500">{item.transactionTag}</p>
            </div>
          </div>

          <div className="text-right">
            <p
              className={`text-xl font-bold ${
                isIncome ? "text-green-600" : "text-red-500"
              }`}
            >
              {formatMoney(item.amount, 2)} €
            </p>
            {item.transactionAccount && (
              <p className="text-sm text-gray-500">{item.transactionAccount}</p>
            )}
          </div>
        </div>
      </div>

      {/* Render context menu only if this item has the active menu */}
      {isActiveMenu && createPortal(contextMenu, document.body)}
    </>
  );
};

export default TransactionsItem;
