import React, { useState } from "react";
import Modal from "../../../Modal/Modal";
import useAppLogic from "../../../../hooks/AppLogic";
import { FaCog } from "react-icons/fa";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

const Settings = ({ onClose }) => {
  const { clearAppCache } = useAppLogic();

  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleShowAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  return (
    <Modal onClose={onClose} title={"Application Settings"}>
      <div className="space-y-4">
        {/* Advanced */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleShowAdvanced}
          >
            <div className="flex items-center space-x-2">
              <FaCog className="text-lg" />
              <h2 className="text-lg font-semibold">Advanced</h2>
            </div>
            <span>{showAdvanced ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {showAdvanced && (
            <div className="mt-4">
              <button
                onClick={() => {
                  clearAppCache(false);
                  onClose();
                }}
                className="mt-1 bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
              >
                Clear Local Cache
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
