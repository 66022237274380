import React, { useEffect, useState } from "react";
import useCryptoLogic from "../../../hooks/CryptoLogic";
import useCryptoStore from "../../../store/CryptoStore";
import Label from "../../Modal/Label";
import { formatMoney } from "../../../services/FormatService/FormatText";

const CryptoSettingsCryptoCurrencies = ({ currentUser }) => {
  const {
    addCryptoCurrency,
    editCryptoCurrency,
    removeCryptoCurrency,
    refreshCryptoCurrencyPrices,
  } = useCryptoLogic();

  const { loading, editing, cryptoCurrencies } = useCryptoStore();

  const [currencies, setCurrencies] = useState([]);

  const [newCurrency, setNewCurrency] = useState({
    id: null,
    ticker: "",
    name: "",
    description: "",
    price: "",
    globalUpdate: true,
  });

  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    const sortedCurrencies = cryptoCurrencies.sort((a, b) => {
      if (a.ticker < b.ticker) return -1;
      if (a.ticker > b.ticker) return 1;
      return 0;
    });

    setCurrencies(sortedCurrencies);
  }, [cryptoCurrencies]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "ticker") {
      setNewCurrency((prev) => ({ ...prev, ticker: value.toUpperCase() }));
    } else if (name === "globalUpdate") {
      setNewCurrency((prev) => ({
        ...prev,
        globalUpdate: checked,
      }));
    } else {
      setNewCurrency((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editingId === null) {
      handleAddCryptoCurrency();
    } else {
      handleEditCryptoCurrency();
    }

    resetForm();
  };

  const handleAddCryptoCurrency = async () => {
    if (newCurrency.price.length === 0) {
      newCurrency.price = "0";
    }

    const result = await addCryptoCurrency(newCurrency);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleEditCryptoCurrency = async () => {
    const result = await editCryptoCurrency(newCurrency);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleEdit = (category) => {
    setNewCurrency(category);
    setEditingId(category.id);
  };

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this cryptocurrency?"
    );
    if (isConfirmed) {
      const category = { userId: currentUser.id, id: id };
      const result = await removeCryptoCurrency(category);

      if (result) {
        alert(
          "Currency couldn't be deleted. Please try again.\n\nTip: Only unused currencies can be deleted."
        );
      }
    }
  };

  const cancelEdit = () => {
    resetForm();
  };

  const resetForm = () => {
    setNewCurrency({
      id: null,
      ticker: "",
      name: "",
      description: "",
      price: "",
    });
    setEditingId(null);
  };

  return (
    <div className="space-y-4">
      <label className="block text-gray-700 dark:text-gray-300 font-semibold text-center">
        All Currencies
      </label>

      <div className="border p-4 mt-4 min-h-64 max-h-64 overflow-y-auto space-y-4 bg-gray-50 dark:bg-gray-300 rounded">
        {loading ? (
          <div className="text-center text-base mt-4 text-black dark:text-gray-300">
            Loading...
          </div>
        ) : (
          <>
            {currencies.length > 0 ? (
              currencies.map((category) => (
                <div
                  key={category.id}
                  className="flex justify-between items-center border-b pb-2"
                >
                  <div>
                    <h3 className="font-bold text-gray-800">
                      {category.name} ({category.ticker})
                    </h3>
                    <p className="text-gray-600">{category.description}</p>
                    <p className="text-gray-600">
                      {formatMoney(category.price)} €
                    </p>
                    {!category.globalUpdate && (
                      <p className="text-red-500">Global update disabled</p>
                    )}
                  </div>

                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(category)}
                      className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(category.id)}
                      className="bg-red-400 text-white py-1 px-3 rounded hover:bg-red-500"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-base mt-4 text-black dark:text-gray-300">
                No cryptocurrencies
              </div>
            )}
          </>
        )}
      </div>

      <div className="flex justify-center">
        <button
          className="bg-blue-500 text-white py-2 px-4 mt-2 rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed"
          onClick={refreshCryptoCurrencyPrices}
          disabled={editing}
        >
          Update prices
        </button>
      </div>

      <label className="block text-gray-700 dark:text-gray-300 font-semibold text-center">
        {editingId === null ? "New Currency" : "Update Currency"}
      </label>

      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <Label text={"Ticker"} isRequired={true} />
          <input
            type="text"
            id="ticker"
            name="ticker"
            value={newCurrency.ticker}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            placeholder="Enter ticker (e.g. BTC)"
            maxLength={10}
            required
          />
          <div className="text-right text-sm text-gray-500">
            {newCurrency.ticker.length}/{10} characters
          </div>
        </div>

        <div className="mb-2">
          <Label text={"Name"} isRequired={true} />
          <input
            type="text"
            id="name"
            name="name"
            value={newCurrency.name}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            placeholder="Enter name"
            maxLength={50}
            required
          />
          <div className="text-right text-sm text-gray-500">
            {newCurrency.name.length}/{50} characters
          </div>
        </div>

        <div className="mb-2">
          <Label text={"Description"} />
          <textarea
            type="text"
            id="description"
            name="description"
            value={newCurrency.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            placeholder="Enter description"
            maxLength={255}
          />
          <div className="text-right text-sm text-gray-500">
            {newCurrency.description.length}/{255} characters
          </div>
        </div>

        <div className="mb-6">
          <Label text={`Price (€)`} />
          <input
            type="tel"
            inputMode="decimal"
            id="price"
            name="price"
            value={newCurrency.price}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            placeholder="Enter price"
          />
        </div>

        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="globalUpdate"
            name="globalUpdate"
            checked={newCurrency.globalUpdate}
            onChange={handleInputChange}
            className="form-checkbox h-5 w-5"
          />
          <label
            htmlFor="globalUpdate"
            className="text-black dark:text-gray-300"
          >
            Global price update
          </label>
        </div>

        <div className="flex justify-end space-x-2">
          {editingId !== null && (
            <button
              type="button"
              onClick={cancelEdit}
              className="bg-gray-300 py-2 px-4 mb-2 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 mb-2 rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed"
            disabled={newCurrency.name.length === 0}
          >
            {editingId === null ? "New Currency" : "Update Currency"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CryptoSettingsCryptoCurrencies;
