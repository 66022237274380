import React, { useState, useEffect } from "react";
import { makeApiCall } from "../../../../services/ApiService/ApiCall";
import { format } from "date-fns";
import useCurrentUserStore from "../../../../store/CurrentUserStore";
import Modal from "../../../Modal/Modal";
import Label from "../../../Modal/Label";
import { FaUserCog, FaUserEdit } from "react-icons/fa";
import { FaChevronDown, FaChevronUp, FaUserLock } from "react-icons/fa6";

const Account = ({
  currentUser,
  currentUserInfo,
  editUser,
  editUserInfo,
  changePassword,
  onClose,
}) => {
  const [mailSent, setMailSent] = useState(false);

  const [showUserAccount, setShowUserAccount] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [showUserProfile, setShowUserProfile] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const { setDataChanged } = useCurrentUserStore();

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
      setUsername(currentUser.username);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUserInfo) {
      setFirstName(currentUserInfo.firstName);
      setLastName(currentUserInfo.lastName);
      setDateOfBirth(currentUserInfo.dateOfBirth);
    }
  }, [currentUserInfo]);

  useEffect(() => {
    setPasswordMatch(newPassword === repeatPassword);
  }, [newPassword, repeatPassword]);

  const handleShowUserAccount = () => {
    setShowUserAccount(!showUserAccount);
    setShowUserProfile(false);
    setShowChangePassword(false);
  };

  const handleShowUserProfile = () => {
    setShowUserProfile(!showUserProfile);
    setShowUserAccount(false);
    setShowChangePassword(false);
  };

  const handleShowChangePassword = () => {
    setShowChangePassword(!showChangePassword);
    setShowUserAccount(false);
    setShowUserProfile(false);
  };

  const handleResendVerificationEmail = async () => {
    setMailSent(true);

    const requestUrl = `/Users/SendVerificationEmail/${currentUser?.id}`;
    const result = await makeApiCall("POST", requestUrl, null);

    if (result.data === 200) {
      alert("Verification email (re)sent successfully.");
    } else {
      alert("Verification email could not be (re)sent.");
    }
  };
  const handleSaveUserAccountChanges = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const result = await editUser({ username, email });

    if (result?.status === 200) {
      setDataChanged(true);
      onClose();
    }
  };

  const handleSaveUserProfileChanges = async () => {
    const result = await editUserInfo({ firstName, lastName, dateOfBirth });

    if (result?.status === 200) {
      setDataChanged(true);
      onClose();
    }
  };

  const handleSaveChangedPassword = async () => {
    if (passwordMatch) {
      const result = await changePassword({ currentPassword, newPassword });

      if (result?.status === 200) {
        onClose();
      }
    }
  };

  return (
    <Modal onClose={onClose} title={"Account Settings"}>
      <div className="space-y-4">
        {/* User Account */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleShowUserAccount}
          >
            <div className="flex items-center space-x-2">
              <FaUserCog className="text-lg" />
              <h2 className="text-lg font-semibold">User Account</h2>
            </div>
            <span>{showUserAccount ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {showUserAccount && (
            <>
              <div className="mb-4">
                <Label text={"Username"} />
                <input
                  type="text"
                  autoCapitalize="none"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black disabled:cursor-not-allowed disabled:bg-gray-100 dark:disabled:bg-gray-400"
                  maxLength={50}
                  disabled={!currentUser.verified}
                  required
                />
              </div>

              <div className="mb-4">
                <Label text={"Email"} />
                <input
                  type="email"
                  autoCapitalize="none"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black disabled:cursor-not-allowed disabled:bg-gray-100 dark:disabled:bg-gray-400"
                  maxLength={255}
                  required
                />
              </div>

              <div className="flex mb-4">
                <button
                  onClick={() => handleResendVerificationEmail()}
                  className="bg-green-500 text-white px-5 py-3 rounded text-base font-medium hover:bg-green-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  disabled={
                    mailSent || currentUser === "" || currentUser.verified
                  }
                >
                  {currentUser.verified ? "Email verified" : "Verify email"}
                </button>

                <div className="ml-auto flex">
                  <button
                    onClick={onClose}
                    className="mr-2 bg-gray-300 py-3 px-5 rounded hover:bg-gray-400 text-black"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => handleSaveUserAccountChanges()}
                    className={`py-3 px-5 rounded text-white bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                    disabled={
                      !username ||
                      !email ||
                      (username === currentUser.username &&
                        email === currentUser.email)
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleShowUserProfile}
          >
            <div className="flex items-center space-x-2">
              <FaUserEdit className="text-lg" />
              <h2 className="text-lg font-semibold">User Profile</h2>
            </div>
            <span>{showUserAccount ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {showUserProfile && (
            <>
              <div className="mb-4">
                <Label text={"First Name"} />
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black disabled:cursor-not-allowed disabled:bg-gray-100 dark:disabled:bg-gray-400"
                  maxLength={50}
                  disabled={!currentUser.verified}
                  required
                />
              </div>

              <div className="mb-4">
                <Label text={"Last Name"} />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black disabled:cursor-not-allowed disabled:bg-gray-100 dark:disabled:bg-gray-400"
                  maxLength={255}
                  disabled={!currentUser.verified}
                  required
                />
              </div>

              <div className="mb-4">
                <Label text={"Date of Birth"} />
                <input
                  type="date"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black disabled:cursor-not-allowed disabled:bg-gray-100 dark:disabled:bg-gray-400"
                  disabled={!currentUser.verified}
                  required
                />
              </div>

              <div className="flex mb-4">
                <div className="ml-auto flex">
                  <button
                    onClick={onClose}
                    className="mr-2 bg-gray-300 py-3 px-5 rounded hover:bg-gray-400 text-black"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => handleSaveUserProfileChanges()}
                    className={`py-3 px-5 rounded text-white bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                    disabled={
                      !firstName ||
                      !lastName ||
                      !dateOfBirth ||
                      (firstName === currentUserInfo.firstName &&
                        lastName === currentUserInfo.lastName &&
                        dateOfBirth === currentUserInfo.dateOfBirth)
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleShowChangePassword}
          >
            <div className="flex items-center space-x-2">
              <FaUserLock className="text-lg" />
              <h2 className="text-lg font-semibold">Change Password</h2>
            </div>
            <span>{showUserAccount ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {showChangePassword && (
            <>
              <div className="mb-4">
                <Label text={"Current Password"} />
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black"
                  maxLength={128}
                />
              </div>

              <div className="mb-4">
                <Label text={"New Password"} />
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border rounded mt-1 text-black"
                  maxLength={128}
                />
              </div>

              <div className="mb-4">
                <Label text={"Repeat Password"} />
                <input
                  type="password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  className={`w-full p-2 border rounded mt-1 text-black ${
                    !passwordMatch ? "border-red-500" : ""
                  }`}
                  maxLength={128}
                />
                {!passwordMatch && (
                  <p className="text-red-500 text-sm mt-1">
                    Passwords do not match.
                  </p>
                )}
              </div>

              <div className="flex">
                <div className="ml-auto flex">
                  <button
                    onClick={onClose}
                    className="mr-2 bg-gray-300 py-3 px-5 rounded hover:bg-gray-400 text-black"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={handleSaveChangedPassword}
                    className={`py-3 px-5 rounded text-white bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                    disabled={
                      !passwordMatch || !currentPassword || !newPassword
                    }
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Account;
