import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import useTransactionsStore from "../../store/TransactionsStore";
import useAssetsStore from "../../store/AssetsStore";
import useCryptoStore from "../../store/CryptoStore";
import useTransactionsLogic from "../../hooks/TransactionsLogic";
import useAssetsLogic from "../../hooks/AssetsLogic";
import useCryptoLogic from "../../hooks/CryptoLogic";
import NetWorthProgress from "../../components/Home/NetWorthProgress";
import Statistics from "../../components/Home/Statistics";
import PieChart from "../../components/Home/PieChart";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const Home = () => {
  const [page] = useState(localStorage.getItem("lastPage"));

  const { transactions } = useTransactionsStore();
  const { setRefreshTransactions } = useTransactionsLogic();

  const { assets } = useAssetsStore();
  const { setRefreshAssets } = useAssetsLogic();

  const { cryptoCurrencies, cryptoTransactions } = useCryptoStore();
  const { setRefreshCrypto } = useCryptoLogic();

  const [overviewData, setOverviewData] = useState({
    balance: null,
    cryptoWorth: null,
    assetWorth: null,
    total: null,
  });

  const [currentChart, setCurrentChart] = useState(0); // 0 for balance, 1 for expenses, 2 for incomes

  const navigate = useNavigate();

  const calculateGoal = (netWorth) => {
    const initialGoal = 2500;
    const multiplier = 2;

    let goal = initialGoal;

    while (netWorth >= goal) {
      goal *= multiplier;
    }

    return goal;
  };

  const totalNetWorth =
    overviewData.balance + overviewData.assetWorth + overviewData.cryptoWorth;

  const goal = calculateGoal(totalNetWorth);

  const percentage = Math.min((totalNetWorth / goal) * 100, 100);

  useEffect(() => {
    const possiblePages = ["assets", "cryptos", "transactions"];

    if (page && possiblePages.includes(page)) {
      navigate(`/${page}`);
    } else {
      navigate("/");
      localStorage.setItem("lastPage", "");
    }
  }, [page, navigate]);

  useEffect(() => {
    setRefreshTransactions(true);
    setRefreshAssets(true);
    setRefreshCrypto(true);
  }, [setRefreshTransactions, setRefreshAssets, setRefreshCrypto]);

  useEffect(() => {
    if (transactions.length || assets.length || cryptoTransactions.length) {
      const balance = transactions
        .filter(
          (transaction) => new Date(transaction.transactionDate) <= new Date()
        )
        .reduce(
          (accumulator, transaction) => accumulator + transaction.amount,
          0
        );

      const cryptoWorth = cryptoTransactions.reduce((total, transaction) => {
        const currency = cryptoCurrencies.find(
          (currency) => currency.id === transaction.cryptoCurrencyId
        );
        return (
          total + transaction.cryptoAmount * (currency ? currency.price : 0)
        );
      }, 0);

      const assetWorth = assets.reduce(
        (total, asset) => total + asset.currentWorth,
        0
      );

      const total = balance + cryptoWorth + assetWorth;

      setOverviewData({
        balance,
        cryptoWorth,
        assetWorth,
        total,
      });
    }
  }, [transactions, assets, cryptoCurrencies, cryptoTransactions]);

  // Calculate expense categories (negative amounts)
  const categoryExpenses = transactions.reduce((acc, transaction) => {
    const { transactionCategory, amount, transactionDate } = transaction;
    if (
      transactionCategory &&
      amount < 0 &&
      new Date(transactionDate).getFullYear() === new Date().getFullYear()
    ) {
      if (!acc[transactionCategory]) acc[transactionCategory] = 0;
      acc[transactionCategory] += amount;
    }
    return acc;
  }, {});

  // Calculate income categories (positive amounts)
  const categoryIncome = transactions.reduce((acc, transaction) => {
    const { transactionCategory, amount, transactionDate } = transaction;
    if (
      transactionCategory &&
      amount > 0 &&
      new Date(transactionDate).getFullYear() === new Date().getFullYear()
    ) {
      if (!acc[transactionCategory]) acc[transactionCategory] = 0;
      acc[transactionCategory] += amount;
    }
    return acc;
  }, {});

  // Sort both income and expenses by amount
  const sortedExpenses = Object.entries(categoryExpenses).sort(
    (a, b) => a[1] - b[1]
  );
  const sortedIncome = Object.entries(categoryIncome).sort(
    (a, b) => b[1] - a[1]
  );

  const topSpendingCategories = sortedExpenses.slice(0, 5);
  const topIncomeCategories = sortedIncome.slice(0, 5);

  if (page) return null;

  return (
    <>
      <Header />
      <Body>
        <NetWorthProgress
          percentage={percentage}
          total={overviewData.total}
          goal={goal}
        />

        <hr className="mt-5" />

        <Statistics
          currentChart={currentChart}
          topSpendingCategories={topSpendingCategories}
          topIncomeCategories={topIncomeCategories}
          overviewData={overviewData}
        />

        <>
          <PieChart
            currentChart={currentChart}
            setCurrentChart={setCurrentChart}
            overviewData={overviewData}
            categoryExpenses={categoryExpenses}
            categoryIncome={categoryIncome}
          />

          <FaChevronLeft
            size={30}
            className="absolute left-2 top-2/4 cursor-pointer hover:opacity-80 text-black dark:text-gray-300"
            onClick={() =>
              setCurrentChart((prev) => (prev === 0 ? 2 : prev - 1))
            }
          />

          <FaChevronRight
            size={30}
            className="absolute right-2 top-2/4 cursor-pointer hover:opacity-80 text-black dark:text-gray-300"
            onClick={() =>
              setCurrentChart((prev) => (prev === 2 ? 0 : prev + 1))
            }
          />
        </>
      </Body>
    </>
  );
};

export default Home;
