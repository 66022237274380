import { formatMoney } from "../../services/FormatService/FormatText";

const Statistics = ({
  currentChart,
  topSpendingCategories,
  topIncomeCategories,
  overviewData,
}) => {
  return (
    <div className="mt-5">
      {currentChart === 0 ? (
        <>
          <h2 className="text-xl font-semibold text-center text-black dark:text-gray-300 mb-2">
            Current Statistics
          </h2>

          <h3 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Liquid Balance: "}
            <span className="font-bold">
              {formatMoney(overviewData.balance, 2)} €
            </span>
          </h3>

          <h3 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Investments: "}
            <span className="font-bold">
              {formatMoney(
                overviewData.assetWorth + overviewData.cryptoWorth,
                2
              )}{" "}
              €
            </span>
            <span className="block text-sm text-gray-500 dark:text-gray-400 mt-1">
              {`(Crypto: ${formatMoney(
                overviewData.cryptoWorth,
                2
              )} €, Assets: ${formatMoney(overviewData.assetWorth, 2)} €)`}
            </span>
          </h3>
        </>
      ) : currentChart === 1 ? (
        <>
          <h2 className="text-xl font-semibold text-center text-black dark:text-gray-300 mb-2">
            Top {topSpendingCategories.length} Expenses (
            {new Date().getFullYear()})
          </h2>

          {topSpendingCategories.map(([category, amount], index) => (
            <h3
              key={category}
              className="text-xl text-center mt-4 text-black dark:text-gray-300"
            >
              {`${index + 1}. ${category}: `}
              <span className="font-bold">{formatMoney(amount, 2)} €</span>
            </h3>
          ))}
        </>
      ) : (
        <>
          <h2 className="text-xl font-semibold text-center text-black dark:text-gray-300 mb-2">
            Top {topIncomeCategories.length} Incomes ({new Date().getFullYear()}
            )
          </h2>

          {topIncomeCategories.map(([category, amount], index) => (
            <h3
              key={category}
              className="text-xl text-center mt-4 text-black dark:text-gray-300"
            >
              {`${index + 1}. ${category}: `}
              <span className="font-bold">{formatMoney(amount, 2)} €</span>
            </h3>
          ))}
        </>
      )}
    </div>
  );
};

export default Statistics;
