import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  formatMoney,
  months,
} from "../../../services/FormatService/FormatText";
import useTransactionsStore from "../../../store/TransactionsStore";

const TransactionsFiltersTimeFrame = ({ setFilteredTransactionsTimeFrame }) => {
  const {
    loading,
    transactions,
    selectedTimeFrame,
    setSelectedTimeFrame,
    selectedAccount,
  } = useTransactionsStore();

  const [currentMonth] = useState(new Date().getMonth());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [currentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const savedSettings = JSON.parse(
    localStorage.getItem("transactionSettings")
  ) || {
    showPeriodEndBalance: true,
  };

  useEffect(() => {
    filterTransactionsByTimeFrame(selectedTimeFrame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeFrame, selectedMonth, selectedYear, transactions]);

  const filterTransactionsByTimeFrame = (id) => {
    if (transactions.length > 0) {
      setSelectedTimeFrame(id);

      const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.transactionDate);

        if (id === 1) {
          // Monthly
          return (
            transactionDate.getFullYear() === selectedYear &&
            transactionDate.getMonth() === selectedMonth
          );
        } else if (id === 2) {
          // Yearly
          return transactionDate.getFullYear() === selectedYear;
        } else if (id === 3) {
          // All time
          return true; // Include all transactions
        }

        return false;
      });

      setFilteredTransactionsTimeFrame(filteredTransactions);
    }
  };

  const handlePrevTimeFrame = () => {
    if (selectedTimeFrame === 1) {
      if (selectedMonth === 0) {
        setSelectedMonth(11);
        setSelectedYear(selectedYear - 1);
      } else {
        setSelectedMonth(selectedMonth - 1);
      }
    } else if (selectedTimeFrame === 2) {
      setSelectedYear(selectedYear - 1);
    }
  };

  const handleNextTimeFrame = () => {
    if (selectedTimeFrame === 1) {
      if (selectedMonth === 11) {
        setSelectedMonth(0);
        setSelectedYear(selectedYear + 1);
      } else {
        setSelectedMonth(selectedMonth + 1);
      }
    } else if (selectedTimeFrame === 2) {
      setSelectedYear(selectedYear + 1);
    }
  };

  const handleCurrentTimeFrame = () => {
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
  };

  const isCurrentTimePeriod = () => {
    if (selectedTimeFrame === 1) {
      return selectedMonth === currentMonth && selectedYear === currentYear;
    } else if (selectedTimeFrame === 2) {
      return selectedYear === currentYear;
    }
  };

  const handleChangeTimeFrame = (id) => {
    filterTransactionsByTimeFrame(id);
  };

  const periodEndBalance = transactions
    .filter((transaction) => {
      const lastDayOfMonth = new Date(
        selectedYear,
        selectedTimeFrame === 1 ? selectedMonth + 1 : 13,
        0,
        23,
        59,
        59
      );
      const transactionDate = new Date(transaction.transactionDate);
      return transactionDate <= lastDayOfMonth;
    })
    .reduce((accumulator, transaction) => accumulator + transaction.amount, 0);

  const accountBalance = transactions
    .filter((transaction) => {
      const lastDayOfMonth = new Date(
        selectedYear,
        selectedTimeFrame === 1 ? selectedMonth + 1 : 13,
        0,
        23,
        59,
        59
      );
      const transactionDate = new Date(transaction.transactionDate);
      return (
        transactionDate <= lastDayOfMonth &&
        transaction.transactionAccountId === selectedAccount
      );
    })
    .reduce((accumulator, transaction) => accumulator + transaction.amount, 0);

  return (
    <>
      {savedSettings.showPeriodEndBalance && selectedTimeFrame !== 3 && (
        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Balance at period end: "}
          <span className="font-bold">
            {formatMoney(periodEndBalance, 2)} €
          </span>
        </h2>
      )}

      {/* Time Frame selector */}
      <div className="text-center mt-4">
        <button
          onClick={() => handleChangeTimeFrame(1)}
          className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 px-5 disabled:cursor-not-allowed"
          disabled={loading}
        >
          Month
        </button>

        <button
          onClick={() => handleChangeTimeFrame(2)}
          className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 mx-2 px-5 disabled:cursor-not-allowed"
          disabled={loading}
        >
          Year
        </button>

        <button
          onClick={() => handleChangeTimeFrame(3)}
          className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 px-3 disabled:cursor-not-allowed"
          disabled={loading}
        >
          All time
        </button>
      </div>

      {/* Time Frame scroller (prev/next) */}
      {selectedTimeFrame !== 3 && (
        <div className="text-xl text-center mt-4">
          <button
            onClick={() => handlePrevTimeFrame()}
            className="border-2 border-blue-500 text-blue-500 px-3 py-1 mx-2 rounded-2xl bg-blue-50 hover:bg-blue-200 dark:bg-gray-800 dark:hover:bg-gray-700 disabled:cursor-not-allowed"
            disabled={loading}
          >
            <FaArrowLeft />
          </button>

          <button
            className={`cursor-pointer ${
              isCurrentTimePeriod() ? "font-semibold" : "font-base"
            } text-black hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-400 disabled:cursor-not-allowed`}
            onClick={() => handleCurrentTimeFrame()}
            disabled={loading}
          >
            {selectedTimeFrame === 1 ? months[selectedMonth + 1] : ""}{" "}
            {selectedTimeFrame !== 3 ? selectedYear : "All time"}
          </button>

          <button
            onClick={() => handleNextTimeFrame()}
            className="border-2 border-blue-500 text-blue-500 px-3 py-1 mx-2 rounded-2xl bg-blue-50 hover:bg-blue-200 dark:bg-gray-800 dark:hover:bg-gray-700 disabled:cursor-not-allowed"
            disabled={loading}
          >
            <FaArrowRight />
          </button>
        </div>
      )}

      {selectedAccount !== -1 && selectedTimeFrame !== 3 && (
        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Account balance at period end: "}
          <span
            className={`font-bold ${
              accountBalance >= 0 ? "text-green-600" : "text-red-500"
            }`}
          >
            {formatMoney(accountBalance, 2)} €
          </span>
        </h2>
      )}
    </>
  );
};

export default TransactionsFiltersTimeFrame;
