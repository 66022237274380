import React, { useEffect, useState } from "react";
import { formatMoney } from "../../../services/FormatService/FormatText";
import TransactionsFiltersTimeFrame from "./TransactionsFiltersTimeFrame";
import TransactionsFiltersAccount from "./TransactionsFiltersAccount";
import TransactionsFiltersCategory from "./TransactionsFiltersCategory";
import useTransactionsStore from "../../../store/TransactionsStore";
import TransactionsFiltersTag from "./TransactionsFiltersTag";
import ElementTransition from "../../../shared/ElementTransition";
import TransactionsChart from "./TransactionsChart";

const TransactionsFilters = ({
  displayTransactions,
  setDisplayTransactions,
  isFiltersOpen,
}) => {
  const {
    transactions,
    selectedTimeFrame,
    selectedCategory,
    setSelectedCategory,
    selectedAccount,
    setSelectedAccount,
    selectedTag,
    setSelectedTag,
  } = useTransactionsStore();

  const [filteredTransactionsTimeFrame, setFilteredTransactionsTimeFrame] =
    useState([]);

  const [filteredTransactionsCategory, setFilteredTransactionsCategory] =
    useState([]);

  const [filteredTransactionsAccount, setFilteredTransactionsAccount] =
    useState([]);

  const [filteredTransactionsTag, setFilteredTransactionsTag] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const savedSettings = JSON.parse(
    localStorage.getItem("transactionSettings")
  ) || {
    showBalanceChart: true,
    showNumberOfTransactions: true,
  };

  const balance = transactions
    .filter(
      (transaction) => new Date(transaction.transactionDate) <= new Date()
    )
    .reduce((accumulator, transaction) => accumulator + transaction.amount, 0);

  const cashFlow = displayTransactions.reduce(
    (accumulator, transaction) => accumulator + transaction.amount,
    0
  );

  const yearToDateCashFlow = displayTransactions
    .filter((transaction) => {
      const transactionDate = new Date(transaction.transactionDate);
      return (
        transactionDate.getFullYear() === new Date().getFullYear() &&
        transactionDate <= new Date()
      );
    })
    .reduce((acc, transaction) => acc + transaction.amount, 0);

  useEffect(() => {
    const filteredTransactions = transactions.filter((transaction) => {
      const matchesTimeFrame = filteredTransactionsTimeFrame.some(
        (filteredTransaction) => filteredTransaction.id === transaction.id
      );

      const matchesCategory =
        selectedCategory === -1 ||
        filteredTransactionsCategory.some(
          (filteredTransaction) => filteredTransaction.id === transaction.id
        );

      const matchesAccount =
        selectedAccount === -1 ||
        filteredTransactionsAccount.some(
          (filteredTransaction) => filteredTransaction.id === transaction.id
        );

      const matchesTag =
        selectedTag === -1 ||
        filteredTransactionsTag.some(
          (filteredTransaction) => filteredTransaction.id === transaction.id
        );

      const matchesDescription = transaction.description
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      return (
        matchesTimeFrame &&
        matchesCategory &&
        matchesAccount &&
        matchesTag &&
        matchesDescription
      );
    });

    setDisplayTransactions(filteredTransactions);
  }, [
    transactions,
    filteredTransactionsTimeFrame,
    filteredTransactionsCategory,
    filteredTransactionsAccount,
    filteredTransactionsTag,
    selectedCategory,
    selectedAccount,
    selectedTag,
    searchQuery,
    setDisplayTransactions,
  ]);

  const handleResetFilters = () => {
    setSelectedCategory(-1);
    setSelectedAccount(-1);
    setSelectedTag(-1);
    setSearchQuery("");
  };

  const formFilteringTitle = () => {
    if (selectedAccount !== -1 && selectedCategory !== -1) {
      return "Filter Flow: ";
    } else if (selectedAccount !== -1) {
      return "Account Flow: ";
    } else if (selectedCategory !== -1) {
      return "Category Flow: ";
    }
  };

  return (
    <>
      {savedSettings.showBalanceChart && (
        <div className="flex justify-center items-center">
          <TransactionsChart
            transactions={transactions}
            selectedTimeFrame={selectedTimeFrame}
          />
        </div>
      )}

      {/* Current balance text */}
      <h2
        className={`text-xl text-center ${
          savedSettings.showBalanceChart ? "mt-2" : "mt-4"
        } text-black dark:text-gray-300`}
      >
        {"Current Balance: "}
        <span className="font-bold">{formatMoney(balance, 2)} €</span>
      </h2>

      <TransactionsFiltersTimeFrame
        setFilteredTransactionsTimeFrame={setFilteredTransactionsTimeFrame}
      />

      {/* Cash Flow / Category Sum text */}
      {selectedCategory === -1 && selectedAccount === -1 ? (
        <>
          {selectedTimeFrame !== 3 && (
            <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
              {selectedTimeFrame === 2
                ? "Whole Year Cash Flow: "
                : "Period Cash Flow: "}
              <span
                className={`font-bold ${
                  cashFlow >= 0 ? "text-green-600" : "text-red-500"
                }`}
              >
                {formatMoney(cashFlow, 2)} €
              </span>
            </h2>
          )}

          {selectedTimeFrame === 2 && (
            <h2 className="text-xl text-center mt-2 text-black dark:text-gray-300">
              {"Year-To-Date Cash Flow: "}
              <span
                className={`font-bold ${
                  yearToDateCashFlow >= 0 ? "text-green-600" : "text-red-500"
                }`}
              >
                {`${formatMoney(yearToDateCashFlow, 2)} €`}
              </span>
            </h2>
          )}

          {selectedTimeFrame === 3 && (
            <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
              {"All Time Cash Flow: "}
              <span
                className={`font-bold ${
                  cashFlow >= 0 ? "text-green-600" : "text-red-500"
                }`}
              >
                {formatMoney(cashFlow, 2)} €
              </span>
            </h2>
          )}
        </>
      ) : (
        <h2
          className={`text-xl text-center ${
            selectedAccount !== -1 ? "mt-2" : "mt-4"
          } text-black dark:text-gray-300`}
        >
          {formFilteringTitle()}
          <span
            className={`font-bold ${
              cashFlow >= 0 ? "text-green-600" : "text-red-500"
            }`}
          >
            {formatMoney(cashFlow, 2)} €
          </span>
        </h2>
      )}

      {isFiltersOpen && (
        <>
          {/* Category & Account filters */}
          <ElementTransition>
            <div className="flex justify-center">
              <div>
                <div className="lg:flex lg:gap-2">
                  {/* Category switcher */}
                  <TransactionsFiltersCategory
                    displayTransactions={displayTransactions}
                    timeFrameTransactions={filteredTransactionsTimeFrame}
                    setFilteredTransactionsCategory={
                      setFilteredTransactionsCategory
                    }
                  />

                  {/* Account switcher */}
                  <TransactionsFiltersAccount
                    displayTransactions={displayTransactions}
                    timeFrameTransactions={filteredTransactionsTimeFrame}
                    setFilteredTransactionsAccount={
                      setFilteredTransactionsAccount
                    }
                  />

                  {/* Tag switcher */}
                  <TransactionsFiltersTag
                    displayTransactions={displayTransactions}
                    timeFrameTransactions={filteredTransactionsTimeFrame}
                    setFilteredTransactionsTag={setFilteredTransactionsTag}
                  />
                </div>
              </div>
            </div>
          </ElementTransition>

          {/* Search */}
          <ElementTransition>
            <div className="mt-4 text-center">
              <div className="relative inline-block">
                <input
                  type="text"
                  placeholder="Search by description"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="p-2 border rounded-md pr-8"
                />
                {searchQuery && (
                  <button
                    className="absolute right-2 mt-1 top-1/3 transform -translate-y-1/2 text-2xl text-gray-400 hover:text-gray-600"
                    onClick={() => setSearchQuery("")}
                  >
                    &times;
                  </button>
                )}
              </div>
            </div>
          </ElementTransition>

          {(selectedCategory !== -1 ||
            selectedAccount !== -1 ||
            searchQuery.length > 0) && (
            <div className="text-center mt-2">
              <button
                onClick={() => handleResetFilters()}
                className="font-semibold text-black hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-400"
              >
                Reset filter
              </button>
            </div>
          )}
        </>
      )}

      {/* Total transactions (number) */}
      {savedSettings.showNumberOfTransactions && (
        <p
          className={`text-center ${
            isFiltersOpen ? "mt-2" : "mt-1"
          } text-black dark:text-gray-300`}
        >
          {displayTransactions.length} transactions
        </p>
      )}
    </>
  );
};

export default TransactionsFilters;
