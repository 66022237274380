import { create } from "zustand";

const useAssetsStore = create((set) => ({
  loading: false,
  editing: false,
  dataChanged: true,
  assets: [],
  assetTypes: [],

  setLoading: (loading) => set({ loading }),

  setEditing: (editing) => set({ editing }),

  setDataChanged: (dataChanged) => set({ dataChanged }),

  setAssets: (newAssets) => set({ assets: newAssets }),

  setAssetTypes: (newAssetTypes) => set({ assetTypes: newAssetTypes }),

  resetAssetsStore: () =>
    set(() => ({
      loading: false,
      editing: false,
      dataChanged: true,
      assets: [],
      assetTypes: [],
    })),
}));

export default useAssetsStore;
