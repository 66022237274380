import React, { useState, useEffect } from "react";

const defaultSettings = {
  showBalanceChart: true,
  showPeriodEndBalance: true,
  showNumberOfTransactions: true,
  divideTransactions: true,
  allTransactionsSort: "latest",
  pastTransactionsSort: "latest",
  upcomingTransactionsSort: "oldest",
};

const TransactionsSettingsPreferences = ({ onPreferencesChanged }) => {
  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    const savedSettings = JSON.parse(
      localStorage.getItem("transactionSettings")
    );
    if (savedSettings) {
      setSettings(savedSettings);
    }
  }, []);

  const handleApplySettings = () => {
    localStorage.setItem("transactionSettings", JSON.stringify(settings));
    onPreferencesChanged();
    alert("Preferences applied successfully!");
  };

  const handleResetSettings = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to reset preferences to default values?"
    );
    if (isConfirmed) {
      setSettings(defaultSettings);
      localStorage.setItem(
        "transactionSettings",
        JSON.stringify(defaultSettings)
      );
      onPreferencesChanged();
      alert("Preferences reset successfully!");
    }
  };

  return (
    <div className="space-y-4">
      <div className="mt-4 space-y-4">
        {/* Show balance chart */}
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="showBalanceChart"
            checked={settings.showBalanceChart}
            onChange={() =>
              setSettings((prev) => ({
                ...prev,
                showBalanceChart: !prev.showBalanceChart,
              }))
            }
            className="form-checkbox h-5 w-5"
          />
          <label
            htmlFor="showBalanceChart"
            className="text-black dark:text-gray-300"
          >
            Show balance chart
          </label>
        </div>

        {/* Show period end balance */}
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="showPeriodEndBalance"
            checked={settings.showPeriodEndBalance}
            onChange={() =>
              setSettings((prev) => ({
                ...prev,
                showPeriodEndBalance: !prev.showPeriodEndBalance,
              }))
            }
            className="form-checkbox h-5 w-5"
          />
          <label
            htmlFor="showPeriodEndBalance"
            className="text-black dark:text-gray-300"
          >
            Show balance at period end
          </label>
        </div>

        {/* Show number of transactions */}
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="showNumberOfTransactions"
            checked={settings.showNumberOfTransactions}
            onChange={() =>
              setSettings((prev) => ({
                ...prev,
                showNumberOfTransactions: !prev.showNumberOfTransactions,
              }))
            }
            className="form-checkbox h-5 w-5"
          />
          <label
            htmlFor="showNumberOfTransactions"
            className="text-black dark:text-gray-300"
          >
            Show number of transactions
          </label>
        </div>

        {/* Divide transactions */}
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="divideTransactions"
            checked={settings.divideTransactions}
            onChange={() =>
              setSettings((prev) => ({
                ...prev,
                divideTransactions: !prev.divideTransactions,
              }))
            }
            className="form-checkbox h-5 w-5"
          />
          <label
            htmlFor="divideTransactions"
            className="text-black dark:text-gray-300"
          >
            Divide past and upcoming transactions
          </label>
        </div>

        {!settings.divideTransactions ? (
          <>
            {/* All transactions sorting */}
            <div className="space-y-2">
              <label className="text-black dark:text-gray-300 font-semibold">
                Transactions sorting:
              </label>
              <div className="flex space-x-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="allSort"
                    value="latest"
                    checked={settings.allTransactionsSort === "latest"}
                    onChange={() =>
                      setSettings((prev) => ({
                        ...prev,
                        allTransactionsSort: "latest",
                      }))
                    }
                    className="form-radio h-4 w-4"
                  />
                  <span className="text-black dark:text-gray-300">
                    Latest first
                  </span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="allSort"
                    value="oldest"
                    checked={settings.allTransactionsSort === "oldest"}
                    onChange={() =>
                      setSettings((prev) => ({
                        ...prev,
                        allTransactionsSort: "oldest",
                      }))
                    }
                    className="form-radio h-4 w-4"
                  />
                  <span className="text-black dark:text-gray-300">
                    Earliest first
                  </span>
                </label>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Past transactions sorting */}
            <div className="space-y-2">
              <label className="text-black dark:text-gray-300 font-semibold">
                Past transactions sorting:
              </label>
              <div className="flex space-x-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="pastSort"
                    value="latest"
                    checked={settings.pastTransactionsSort === "latest"}
                    onChange={() =>
                      setSettings((prev) => ({
                        ...prev,
                        pastTransactionsSort: "latest",
                      }))
                    }
                    className="form-radio h-4 w-4"
                  />
                  <span className="text-black dark:text-gray-300">
                    Latest first
                  </span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="pastSort"
                    value="oldest"
                    checked={settings.pastTransactionsSort === "oldest"}
                    onChange={() =>
                      setSettings((prev) => ({
                        ...prev,
                        pastTransactionsSort: "oldest",
                      }))
                    }
                    className="form-radio h-4 w-4"
                  />
                  <span className="text-black dark:text-gray-300">
                    Earliest first
                  </span>
                </label>
              </div>
            </div>

            {/* Upcoming transactions sorting */}
            <div className="space-y-2">
              <label className="text-black dark:text-gray-300 font-semibold">
                Upcoming transactions sorting:
              </label>
              <div className="flex space-x-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="upcomingSort"
                    value="latest"
                    checked={settings.upcomingTransactionsSort === "latest"}
                    onChange={() =>
                      setSettings((prev) => ({
                        ...prev,
                        upcomingTransactionsSort: "latest",
                      }))
                    }
                    className="form-radio h-4 w-4"
                  />
                  <span className="text-black dark:text-gray-300">
                    Latest first
                  </span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="upcomingSort"
                    value="oldest"
                    checked={settings.upcomingTransactionsSort === "oldest"}
                    onChange={() =>
                      setSettings((prev) => ({
                        ...prev,
                        upcomingTransactionsSort: "oldest",
                      }))
                    }
                    className="form-radio h-4 w-4"
                  />
                  <span className="text-black dark:text-gray-300">
                    Earliest first
                  </span>
                </label>
              </div>
            </div>
          </>
        )}

        <div className="ml-auto flex justify-end space-x-2">
          <button
            className={`py-2 px-4 mb-2 rounded bg-gray-500 text-white hover:bg-gray-600`}
            onClick={handleResetSettings}
          >
            Reset
          </button>

          <button
            className={`py-2 px-4 mb-2 rounded bg-blue-500 text-white hover:bg-blue-600`}
            onClick={handleApplySettings}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSettingsPreferences;
