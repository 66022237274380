import React, { useRef, useEffect } from "react";
import { FaXmark } from "react-icons/fa6";
import PageTransition from "../../shared/PageTransition";

const Modal = ({ onClose, title, showFootnote, children }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    // Disable background scrolling when the modal is open
    document.body.style.overflow = "hidden";

    // Cleanup: enable scrolling when the modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white dark:bg-gray-900 p-6 rounded-lg shadow-lg w-full max-w-[95vw] md:max-w-lg max-h-[80vh] overflow-y-auto"
      >
        <PageTransition isModal={true}>
          {/* Title */}
          {title && (
            <h1 className="text-xl font-bold mb-6 text-center text-black dark:text-gray-300">
              {title}
            </h1>
          )}

          {/* Close button */}
          <div className="absolute -top-4 -right-4">
            <button
              onClick={() => onClose()}
              className="text-gray-400 p-2 rounded hover:text-gray-600"
            >
              <FaXmark size={20} />
            </button>
          </div>

          {/* Content */}
          {children}

          {/* Required fields info */}
          {showFootnote && (
            <div className="flex">
              <label className="text-red-500">*</label>

              <label className="text-gray-700 dark:text-gray-400">
                Required fields
              </label>
            </div>
          )}
        </PageTransition>
      </div>
    </div>
  );
};

export default Modal;
