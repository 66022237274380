import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import useTransactionsStore from "../../store/TransactionsStore";
import Modal from "../Modal/Modal";
import Label from "../Modal/Label";

const TransactionsModal = ({
  initialData,
  isCopy,
  onSave,
  onDelete,
  onClose,
}) => {
  const { transactionCategories, transactionAccounts, transactionTags } =
    useTransactionsStore();

  const [duplicateTransaction, setDuplicateTransaction] = useState(
    isCopy || false
  );

  const [formState, setFormState] = useState({
    amount: initialData ? initialData.amount.toString() : "",
    date: format(
      initialData && !duplicateTransaction
        ? new Date(initialData.transactionDate)
        : new Date(),
      "yyyy-MM-dd"
    ),
    description: initialData ? initialData.description : "",
    category: initialData ? initialData.transactionCategoryId : "",
    account: initialData?.transactionAccountId || "",
    tag: initialData?.transactionTagId || "",
    type: initialData
      ? initialData.amount < 0
        ? "expense"
        : "income"
      : "expense",
  });

  useEffect(() => {
    if (!initialData && transactionCategories.length > 0) {
      setFormState((prev) => ({
        ...prev,
        category: transactionCategories.find(
          (cat) => cat.isIncome === (formState.type === "income")
        ).id,
      }));
    }

    const lastTransactionData = JSON.parse(
      localStorage.getItem("lastTransactionDate")
    );

    if (!initialData && lastTransactionData) {
      const lastTransactionDate = new Date(lastTransactionData.currentDate);
      const currentDate = new Date();
      const fifteenMinutesAgo = new Date(currentDate.getTime() - 15 * 60000);

      if (lastTransactionDate > fifteenMinutesAgo) {
        setFormState((prev) => ({
          ...prev,
          date: format(
            new Date(lastTransactionData.transactionDate),
            "yyyy-MM-dd"
          ),
        }));
      }
    }

    let updatedAmount = formState.amount;
    if (formState.type === "expense" && !updatedAmount.startsWith("-")) {
      updatedAmount = `-${updatedAmount}`;
    } else if (formState.type === "income" && updatedAmount.startsWith("-")) {
      updatedAmount = updatedAmount.replace(/^-/, "");
    }

    setFormState((prev) => ({ ...prev, amount: updatedAmount }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.type, transactionCategories]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      let newValue = value;
      if (formState.type === "expense" && !newValue.startsWith("-")) {
        newValue = `-${newValue}`;
      } else if (formState.type === "income" && newValue.startsWith("-")) {
        newValue = newValue.replace(/^-/, "");
      }

      if (newValue.includes(",")) {
        newValue = newValue.replace(/,/g, ".");
      }

      const regex =
        formState.type === "income" ? /^\d*\.?\d{0,2}$/ : /^-?\d*\.?\d{0,2}$/;
      if (regex.test(newValue) || newValue === "") {
        setFormState((prev) => ({ ...prev, amount: newValue }));
      }
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = () => {
    const normalizedAmount = formState.amount.replace(/,/g, ".");
    const processedAmount = parseFloat(
      normalizedAmount !== "" ? normalizedAmount : 0
    );

    if (processedAmount === 0) {
      alert("Amount can't be 0, please enter a correct amount.");
      return;
    }

    const transaction = {
      id: initialData
        ? duplicateTransaction
          ? undefined
          : initialData.id
        : undefined,
      amount: processedAmount,
      date: new Date(
        formState.date !== "" ? formState.date : new Date()
      ).toISOString(),
      description: formState.description.replace(/;/g, ","), // Handle CSV export
      category: formState.category,
      account: formState.account || undefined,
      tag: formState.tag || undefined,
    };

    localStorage.setItem(
      "lastTransactionDate",
      JSON.stringify({
        currentDate: new Date().toISOString(),
        transactionDate: transaction.date,
      })
    );

    onSave(transaction);
    onClose();
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this transaction?"
    );
    if (isConfirmed) {
      onDelete(initialData);
      onClose();
    }
  };

  const handleDuplicate = () => {
    setDuplicateTransaction(true);

    formState.date = format(new Date(), "yyyy-MM-dd"); // Might get removed later
  };

  const handleCancel = () => {
    let showDialog = false;

    if (initialData) {
      const hasChanges =
        formState.description !== initialData.description ||
        formState.amount.toString() !== initialData.amount.toString() ||
        formState.date !==
          format(new Date(initialData.transactionDate), "yyyy-MM-dd") ||
        formState.category !== initialData.transactionCategoryId ||
        (formState.account !== "" &&
          formState.account !== initialData.transactionAccountId);

      showDialog = hasChanges;
    } else {
      const hasData =
        formState.description.length > 0 ||
        (formState.type === "income" && formState.amount.length > 0) ||
        (formState.type === "expense" && formState.amount.length > 1);

      showDialog = hasData;
    }

    if (showDialog) {
      const isConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to cancel?"
      );
      if (isConfirmed) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleCancel}
      title={
        initialData && !duplicateTransaction
          ? "Edit Transaction"
          : "New Transaction"
      }
      showFootnote={true}
    >
      <div className="mb-4">
        <Label text={"Type"} isRequired={true} />
        <div className="flex items-center">
          <label className="mr-4 text-black dark:text-gray-300">
            <input
              type="radio"
              name="type"
              value="expense"
              checked={formState.type === "expense"}
              onChange={handleChange}
              className="mr-2"
            />
            Expense
          </label>
          <label className="text-black dark:text-gray-300">
            <input
              type="radio"
              name="type"
              value="income"
              checked={formState.type === "income"}
              onChange={handleChange}
              className="mr-2"
            />
            Income
          </label>
        </div>
      </div>

      <div className="mb-4">
        <Label text={"Amount (€)"} isRequired={true} />
        <input
          type="tel"
          inputMode="decimal"
          name="amount"
          value={formState.amount}
          onChange={handleChange}
          className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
          placeholder="Enter amount"
          required
        />
      </div>

      <div className="mb-4">
        <Label text={"Date"} isRequired={true} />
        <input
          type="date"
          name="date"
          value={formState.date}
          onChange={handleChange}
          className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
          required
        />
      </div>

      <div className="">
        <Label text={"Description"} />
        <textarea
          name="description"
          value={formState.description}
          onChange={handleChange}
          className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
          placeholder="Enter transaction description"
          maxLength={255}
        />
        <div className="text-right text-sm text-gray-500">
          {formState.description.length}/{255} characters
        </div>
      </div>

      <div className="mb-4">
        <Label text={"Category"} isRequired={true} />
        <select
          name="category"
          value={formState.category}
          onChange={handleChange}
          className="w-full p-2 rounded mt-1 border bg-gray-50 dark:bg-gray-300"
          required
        >
          <option value="" disabled>
            Select category
          </option>
          {transactionCategories
            .filter((cat) => cat.isIncome === (formState.type === "income"))
            .map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
        </select>
      </div>

      {transactionAccounts.length > 0 && (
        <div className="mb-4">
          <Label text={"Account"} />
          <select
            name="account"
            value={formState.account}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-gray-50 dark:bg-gray-300"
          >
            <option value="">None</option>
            {transactionAccounts.map((acc) => (
              <option key={acc.id} value={acc.id}>
                {acc.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {transactionTags.length > 0 && (
        <div className="mb-4">
          <Label text={"Tag"} />
          <select
            name="tag"
            value={formState.tag}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-gray-50 dark:bg-gray-300"
          >
            <option value="">None</option>
            {transactionTags.map((acc) => (
              <option key={acc.id} value={acc.id}>
                {acc.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="flex mt-6 mb-3">
        {initialData && !duplicateTransaction && (
          <>
            <button
              onClick={handleDelete}
              className="mr-2 bg-red-400 py-3 px-5 rounded hover:bg-red-500"
            >
              Delete
            </button>

            <button
              onClick={handleDuplicate}
              className="mr-2 bg-yellow-400 py-3 px-5 rounded hover:bg-red-500"
            >
              Copy
            </button>
          </>
        )}

        <div className="ml-auto flex">
          <button
            type="button"
            onClick={handleCancel}
            className="mr-2 bg-gray-300 py-3 px-5 rounded hover:bg-gray-400"
          >
            Cancel
          </button>

          <button
            onClick={handleSave}
            className={`py-3 px-5 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed`}
            disabled={formState.amount.length === 0 || formState.amount === "-"}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionsModal;
