import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import Label from "../Modal/Label";
import { format } from "date-fns";
import useCryptoStore from "../../store/CryptoStore";

const CryptoModal = ({ onSave, onDelete, onClose, initialData }) => {
  const { cryptoCurrencies } = useCryptoStore();

  const [formState, setFormState] = useState({
    id: "",
    cryptoCurrencyId: "",
    cryptoAmount: "",
    fiatCurrency: "",
    fiatAmount: "",
    description: "",
    transactionDate: format(new Date(), "yyyy-MM-dd"),
    type: "Purchase",
  });

  useEffect(() => {
    if (initialData) {
      setFormState({
        id: initialData.id,
        cryptoCurrencyId: initialData.cryptoCurrencyId,
        cryptoAmount: Math.abs(initialData.cryptoAmount).toString(),
        fiatAmount: Math.abs(initialData.fiatAmount).toString(),
        description: initialData.description ? initialData.description : "",
        transactionDate: format(
          new Date(initialData.transactionDate),
          "yyyy-MM-dd"
        ),
        type: initialData.cryptoAmount > 0 ? "Purchase" : "Sale",
      });
    }
  }, [initialData]);

  const handleNumberInput = (value, decimalPoints) => {
    const regex = /^\d*[.,]?\d*$/;
    if (regex.test(value)) {
      let processedValue = value.replace(",", ".");
      if (decimalPoints !== null) {
        const [integerPart, decimalPart] = processedValue.split(".");
        if (decimalPart && decimalPart.length > decimalPoints) {
          processedValue = `${integerPart}.${decimalPart.substring(
            0,
            decimalPoints
          )}`;
        }
      }
      return processedValue;
    }
    return null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "cryptoAmount") {
      const processedValue = handleNumberInput(value, 10);
      if (processedValue !== null) {
        setFormState((prev) => ({ ...prev, cryptoAmount: processedValue }));
      }
    } else if (name === "fiatAmount") {
      const processedValue = handleNumberInput(value, 2);
      if (processedValue !== null) {
        setFormState((prev) => ({ ...prev, fiatAmount: processedValue }));
      }
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const transactionToSave = {
      ...formState,
      cryptoCurrencyId: formState.cryptoCurrencyId,
      cryptoAmount:
        formState.cryptoAmount !== ""
          ? parseFloat(
              formState.type === "Sale"
                ? `-${formState.cryptoAmount}`
                : formState.cryptoAmount
            )
          : 0,
      fiatCurrency: "€", // hardcoded for now
      fiatAmount:
        formState.fiatAmount !== ""
          ? parseFloat(
              formState.type === "Sale"
                ? `-${formState.fiatAmount}`
                : formState.fiatAmount
            )
          : 0,
      transactionDate: new Date(formState.transactionDate).toISOString(),
    };

    console.log(transactionToSave);
    onSave(transactionToSave);
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this transaction?"
    );
    if (isConfirmed) {
      onDelete(initialData);
      onClose();
    }
  };

  const handleCancel = () => {
    let showDialog = false;

    if (initialData) {
      const { cryptoCurrencyId, cryptoAmount, fiatAmount, transactionDate } =
        initialData;
      const hasChanges =
        formState.cryptoCurrencyId !== cryptoCurrencyId ||
        formState.cryptoAmount !== cryptoAmount.toString() ||
        formState.fiatAmount !== fiatAmount.toString() ||
        formState.transactionDate !==
          format(new Date(transactionDate), "yyyy-MM-dd");

      showDialog = hasChanges;
    } else {
      const { cryptoCurrencyId, transactionDate, type, ...fieldsToCheck } =
        formState;

      const hasData = Object.values(fieldsToCheck).some(
        (value) => value !== "" && value !== null
      );

      if (hasData) {
        showDialog = true;
      }
    }

    if (showDialog) {
      const isConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to cancel?"
      );
      if (isConfirmed) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleCancel}
      title={initialData ? "Edit Transaction" : "New Transaction"}
      showFootnote={true}
    >
      <form onSubmit={handleSubmit}>
        {/* Crypto combobox */}
        <div className="mb-4">
          <Label text={"Cryptocurrency"} isRequired={true} />
          <select
            name="cryptoCurrencyId"
            value={formState.cryptoCurrencyId}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-gray-50 dark:bg-gray-300"
            required
          >
            <option value="" disabled>
              Select a Cryptocurrency
            </option>
            {cryptoCurrencies.map((crypto) => (
              <option key={crypto.id} value={crypto.id}>
                {crypto.name} ({crypto.ticker})
              </option>
            ))}
          </select>
        </div>

        {/* Type selector */}
        <Label text={"Type"} isRequired={true} />
        <div className="flex items-center mb-4">
          <label className="mr-4 text-black dark:text-gray-300">
            <input
              type="radio"
              name="type"
              value="Purchase"
              checked={formState.type === "Purchase"}
              onChange={handleChange}
              className="mr-2"
            />
            Purchase
          </label>
          <label className="text-black dark:text-gray-300">
            <input
              type="radio"
              name="type"
              value="Sale"
              checked={formState.type === "Sale"}
              onChange={handleChange}
              className="mr-2"
            />
            Sale
          </label>
        </div>

        {/* Date Field */}
        <div className="mb-2">
          <Label text={"Date"} isRequired={true} />
          <input
            type="date"
            name="transactionDate"
            value={formState.transactionDate}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            required
          />
        </div>

        {/* Quantity Field */}
        <div className="mb-4">
          <Label text={"Quantity"} isRequired={true} />
          <input
            type="tel"
            inputMode="decimal"
            name="cryptoAmount"
            value={formState.cryptoAmount}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter quantity"
            required
          />
        </div>

        {/* Purchase Price Field */}
        <div className="mb-4">
          <Label text={`${formState.type} Price (€)`} isRequired={true} />
          <input
            type="tel"
            inputMode="decimal"
            name="fiatAmount"
            value={formState.fiatAmount}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter purchase price"
            required
          />
        </div>

        {/* Description Field */}
        <div className="mb-2">
          <Label text={"Description"} />
          <textarea
            name="description"
            value={formState.description}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter transaction description"
            maxLength={255}
          />
          <div className="text-right text-sm text-gray-500">
            {formState.description.length}/{255} characters
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex mt-6 mb-3">
          {initialData && (
            <button
              type="button"
              onClick={handleDelete}
              className="mr-2 bg-red-400 py-3 px-5 rounded hover:bg-red-500"
            >
              Delete
            </button>
          )}

          <div className="ml-auto flex">
            <button
              type="button"
              onClick={handleCancel}
              className="mr-2 bg-gray-300 py-3 px-5 rounded hover:bg-gray-400"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="py-3 px-5 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed"
              disabled={
                formState.cryptoAmount.length === 0 ||
                formState.fiatAmount.length === 0
              }
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CryptoModal;
