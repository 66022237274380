import { useState, useEffect } from "react";
import useCryptoStore from "../store/CryptoStore";
import useCryptoData from "../services/DataService/CryptoData";

const useCryptoLogic = () => {
  const {
    setLoading,
    setEditing,
    dataChanged,
    setDataChanged,
    setCryptoCurrencies,
    setCryptoTransactions,
    resetCryptoStore,
  } = useCryptoStore();

  const {
    getCryptoCurrencies,
    getCryptoTransactions,
    createCryptoCurrency,
    createCryptoTransaction,
    updateCryptoCurrency,
    updateCryptoTransaction,
    updateCryptoCurrencyPrices,
    deleteCryptoCurrency,
    deleteCryptoTransaction,
  } = useCryptoData();

  const [refreshCrypto, setRefreshCrypto] = useState(false);

  useEffect(() => {
    if (dataChanged) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged, refreshCrypto]);

  const loadData = async () => {
    try {
      setLoading(true);

      let cryptoCurrenciesData = await loadCryptoCurrencies();
      setCryptoCurrencies(cryptoCurrenciesData);

      let cryptoTransactionsData = await loadCryptoTransactions(
        cryptoCurrenciesData
      );
      setCryptoTransactions(cryptoTransactionsData);
    } catch (error) {
      console.error("Failed to fetch crypto currencies data: ", error?.message);
    } finally {
      setDataChanged(false);
      setLoading(false);
    }
  };

  // LOAD

  const loadCryptoCurrencies = async (cryptoTransactionsData) => {
    let cryptoCurrenciesData = JSON.parse(
      localStorage.getItem("cryptoCurrencies")
    );

    if (!cryptoCurrenciesData) {
      cryptoCurrenciesData = await getCryptoCurrencies(cryptoTransactionsData);
      if (cryptoCurrenciesData) {
        localStorage.setItem(
          "cryptoCurrencies",
          JSON.stringify(cryptoCurrenciesData)
        );
      }
    }

    return cryptoCurrenciesData;
  };

  const loadCryptoTransactions = async (cryptoCurrenciesData) => {
    let cryptoTransactionsData = JSON.parse(
      localStorage.getItem("cryptoTransactions")
    );

    if (cryptoTransactionsData) {
      cryptoTransactionsData.sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
    } else {
      cryptoTransactionsData = await getCryptoTransactions(
        cryptoCurrenciesData
      );

      if (cryptoTransactionsData) {
        localStorage.setItem(
          "cryptoTransactions",
          JSON.stringify(cryptoTransactionsData)
        );
      }
    }

    return cryptoTransactionsData;
  };

  // ADD

  const addCryptoCurrency = async (item) => {
    setEditing(true);

    try {
      const result = await createCryptoCurrency(item);

      localStorage.removeItem("cryptoCurrencies");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const addCryptoTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await createCryptoTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Add necessary fields
        const newTransaction = result.data;

        // Update local storage
        const currentTransactions =
          JSON.parse(localStorage.getItem("cryptoTransactions")) || [];

        localStorage.setItem(
          "cryptoTransactions",
          JSON.stringify([...currentTransactions, newTransaction])
        );

        // Refresh view
        setDataChanged(true);
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  // EDIT

  const editCryptoCurrency = async (item) => {
    setEditing(true);

    try {
      const result = await updateCryptoCurrency(item);

      localStorage.removeItem("cryptoCurrencies");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const editCryptoTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await updateCryptoTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Add necessary fields
        const editedTransaction = result.data;

        // Update local storage
        let currentTransactions =
          JSON.parse(localStorage.getItem("cryptoTransactions")) || [];

        currentTransactions = currentTransactions.map((tx) =>
          tx.id === editedTransaction.id ? editedTransaction : tx
        );

        localStorage.setItem(
          "cryptoTransactions",
          JSON.stringify(currentTransactions)
        );

        // Refresh view
        setDataChanged(true);
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  // REFRESH

  const refreshCryptoCurrencyPrices = async () => {
    setEditing(true);

    try {
      const result = await updateCryptoCurrencyPrices();

      if (result.data) {
        localStorage.setItem("cryptoCurrencies", JSON.stringify(result.data));
        setCryptoCurrencies(result.data);
        setDataChanged(true);
      } else {
        return result;
      }
    } catch (error) {
      alert(error?.message);
    }

    setEditing(false);
  };

  // REMOVE

  const removeCryptoCurrency = async (item) => {
    setEditing(true);

    try {
      const result = await deleteCryptoCurrency(item);

      localStorage.removeItem("cryptoCurrencies");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const removeCryptoTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await deleteCryptoTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Update local storage
        let currentTransactions =
          JSON.parse(localStorage.getItem("cryptoTransactions")) || [];

        currentTransactions = currentTransactions.filter(
          (tx) => tx.id !== item.id
        );

        localStorage.setItem(
          "cryptoTransactions",
          JSON.stringify(currentTransactions)
        );

        // Reset view
        setDataChanged(true);
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  // CLEAR

  const clearCryptoCurrenciesCache = () => {
    localStorage.removeItem("cryptoCurrencies");
    localStorage.removeItem("cryptoTransactions");

    resetCryptoStore();

    refreshCryptoCurrencyPrices(); // careful not to abuse

    setDataChanged(true);
  };

  return {
    addCryptoCurrency,
    addCryptoTransaction,
    editCryptoCurrency,
    editCryptoTransaction,
    refreshCryptoCurrencyPrices,
    removeCryptoCurrency,
    removeCryptoTransaction,
    clearCryptoCurrenciesCache,
    setRefreshCrypto,
  };
};

export default useCryptoLogic;
