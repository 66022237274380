import { useEffect, useState } from "react";
import useTransactionsData from "../services/DataService/TransactionsData";
import useTransactionsStore from "../store/TransactionsStore";

const useTransactionsLogic = () => {
  const {
    setLoading,
    setEditing,
    dataChanged,
    setDataChanged,
    setTransactions,
    transactionAccounts,
    setTransactionAccounts,
    transactionCategories,
    setTransactionCategories,
    transactionTags,
    setTransactionTags,
    resetTransactionsStore,
  } = useTransactionsStore();

  const {
    getTransactions,
    getTransactionCategories,
    getTransactionAccounts,
    getTransactionTags,
    createTransaction,
    createTransactionCategory,
    createTransactionAccount,
    createTransactionTag,
    exportTransactions,
    updateTransaction,
    updateTransactionCategory,
    updateTransactionAccount,
    updateTransactionTag,
    deleteTransaction,
    deleteTransactionCategory,
    deleteTransactionAccount,
    deleteTransactionTag,
  } = useTransactionsData();

  const [refreshTransactions, setRefreshTransactions] = useState(false);

  useEffect(() => {
    if (dataChanged) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged, refreshTransactions]);

  const loadData = async () => {
    try {
      setLoading(true);

      let transactionCategoriesData = await loadTransactionCategories();
      setTransactionCategories(transactionCategoriesData);

      let transactionAccountsData = await loadTransactionAccounts();
      setTransactionAccounts(transactionAccountsData);

      let transactionTagsData = await loadTransactionTags();
      setTransactionTags(transactionTagsData);

      let transactionsData = await loadTransactions(
        transactionCategoriesData,
        transactionAccountsData,
        transactionTagsData
      );
      setTransactions(transactionsData);
    } catch (error) {
      console.error("Failed to fetch data: ", error?.message);
    } finally {
      setDataChanged(false);
      setLoading(false);
    }
  };

  // LOAD

  const loadTransactionCategories = async () => {
    let transactionCategoriesData = JSON.parse(
      localStorage.getItem("transactionCategories")
    );

    if (!transactionCategoriesData) {
      transactionCategoriesData = await getTransactionCategories();

      if (transactionCategoriesData) {
        transactionCategoriesData.sort((a, b) => a.name.localeCompare(b.name));

        localStorage.setItem(
          "transactionCategories",
          JSON.stringify(transactionCategoriesData)
        );
      }
    }

    return transactionCategoriesData;
  };

  const loadTransactionAccounts = async () => {
    let transactionAccountsData = JSON.parse(
      localStorage.getItem("transactionAccounts")
    );

    if (!transactionAccountsData) {
      transactionAccountsData = await getTransactionAccounts();

      if (transactionAccountsData) {
        transactionAccountsData.sort((a, b) => a.name.localeCompare(b.name));

        localStorage.setItem(
          "transactionAccounts",
          JSON.stringify(transactionAccountsData)
        );
      }
    }

    return transactionAccountsData;
  };

  const loadTransactionTags = async () => {
    let transactionTagsData = JSON.parse(
      localStorage.getItem("transactionTags")
    );

    if (!transactionTagsData) {
      transactionTagsData = await getTransactionTags();

      if (transactionTagsData) {
        transactionTagsData.sort((a, b) => a.name.localeCompare(b.name));

        localStorage.setItem(
          "transactionTags",
          JSON.stringify(transactionTagsData)
        );
      }
    }

    return transactionTagsData;
  };

  const loadTransactions = async (
    transactionCategoriesData,
    transactionAccountsData,
    transactionTagsData
  ) => {
    let transactionsData = JSON.parse(localStorage.getItem("transactions"));

    if (transactionsData) {
      transactionsData.sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
    } else {
      transactionsData = await getTransactions(
        transactionCategoriesData,
        transactionAccountsData,
        transactionTagsData
      );
      if (transactionsData) {
        localStorage.setItem("transactions", JSON.stringify(transactionsData));
      }
    }

    return transactionsData;
  };

  // ADD

  const addTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await createTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Add necessary fields
        const newTransaction = prepareTransaction(result.data);

        // Update local storage
        const currentTransactions =
          JSON.parse(localStorage.getItem("transactions")) || [];

        localStorage.setItem(
          "transactions",
          JSON.stringify([...currentTransactions, newTransaction])
        );

        // Refresh view
        setDataChanged(true);
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const addTransactionCategory = async (item) => {
    setEditing(true);

    try {
      const result = await createTransactionCategory(item);

      localStorage.removeItem("transactionCategories");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const addTransactionAccount = async (item) => {
    setEditing(true);

    try {
      const result = await createTransactionAccount(item);

      localStorage.removeItem("transactionAccounts");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const addTransactionTag = async (item) => {
    setEditing(true);

    try {
      const result = await createTransactionTag(item);

      localStorage.removeItem("transactionTags");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  // EDIT

  const editTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await updateTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Add necessary fields
        const editedTransaction = prepareTransaction(result.data);

        // Update local storage
        let currentTransactions =
          JSON.parse(localStorage.getItem("transactions")) || [];

        currentTransactions = currentTransactions.map((tx) =>
          tx.id === editedTransaction.id ? editedTransaction : tx
        );

        localStorage.setItem(
          "transactions",
          JSON.stringify(currentTransactions)
        );

        // Refresh view
        setDataChanged(true);
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const editTransactionCategory = async (item) => {
    setEditing(true);

    try {
      const result = await updateTransactionCategory(item);

      localStorage.removeItem("transactionCategories");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const editTransactionAccount = async (item) => {
    setEditing(true);

    try {
      const result = await updateTransactionAccount(item);

      localStorage.removeItem("transactionAccounts");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const editTransactionTag = async (item) => {
    setEditing(true);

    try {
      const result = await updateTransactionTag(item);

      localStorage.removeItem("transactionTags");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  // REMOVE

  const removeTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await deleteTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Update local storage
        let currentTransactions =
          JSON.parse(localStorage.getItem("transactions")) || [];

        currentTransactions = currentTransactions.filter(
          (tx) => tx.id !== item.id
        );

        localStorage.setItem(
          "transactions",
          JSON.stringify(currentTransactions)
        );

        // Reset view
        setDataChanged(true);
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const removeTransactionCategory = async (item) => {
    setEditing(true);

    try {
      const result = await deleteTransactionCategory(item);

      localStorage.removeItem("transactionCategories");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const removeTransactionAccount = async (item) => {
    setEditing(true);

    try {
      const result = await deleteTransactionAccount(item);

      localStorage.removeItem("transactionAccounts");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  const removeTransactionTag = async (item) => {
    setEditing(true);

    try {
      const result = await deleteTransactionTag(item);

      localStorage.removeItem("transactionTags");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }

    setEditing(false);
  };

  // EXPORT

  const exportTransactionsViaEmail = async (item) => {
    try {
      const result = await exportTransactions(item);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // CLEAR

  const clearTransactionsCache = () => {
    localStorage.removeItem("transactions");
    localStorage.removeItem("transactionCategories");
    localStorage.removeItem("transactionAccounts");
    localStorage.removeItem("transactionTags");

    resetTransactionsStore();

    setDataChanged(true);
  };

  // HELPERS

  const prepareTransaction = (data) => {
    const category = transactionCategories.find(
      (c) => c.id === data.transactionCategoryId
    );
    data.transactionCategory = category ? category.name : "Unknown Category";

    const account = transactionAccounts.find(
      (a) => a.id === data.transactionAccountId
    );
    data.transactionAccount = account?.name;

    const tag = transactionTags.find((a) => a.id === data.transactionTagId);
    data.transactionTag = tag?.name;

    return data;
  };

  // EXPORT

  return {
    addTransaction,
    editTransaction,
    removeTransaction,
    addTransactionCategory,
    editTransactionCategory,
    removeTransactionCategory,
    addTransactionAccount,
    editTransactionAccount,
    removeTransactionAccount,
    addTransactionTag,
    editTransactionTag,
    removeTransactionTag,
    exportTransactionsViaEmail,
    clearTransactionsCache,
    setRefreshTransactions,
  };
};

export default useTransactionsLogic;
