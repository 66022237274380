import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TransactionsChart = ({ transactions, selectedTimeFrame }) => {
  const getBalanceData = () => {
    const currentDate = new Date();
    const balanceData = [];
    const labels = [];

    if (selectedTimeFrame === 1) {
      // Calculate balance for the last 12 months
      for (let i = 12; i >= 0; i--) {
        const date = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - i,
          1
        );
        const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        const monthBalance = transactions
          .filter(
            (transaction) => new Date(transaction.transactionDate) <= endOfMonth
          )
          .reduce((acc, transaction) => acc + transaction.amount, 0);

        balanceData.push(monthBalance);
        labels.push(
          date.toLocaleString("default", { month: "short", year: "numeric" })
        );
      }
    } else {
      // Calculate balance for the last 5 years
      for (let i = 4; i >= 0; i--) {
        const year = currentDate.getFullYear() - i;
        const endOfYear = new Date(year, 11, 31);

        const yearBalance = transactions
          .filter(
            (transaction) => new Date(transaction.transactionDate) <= endOfYear
          )
          .reduce((acc, transaction) => acc + transaction.amount, 0);

        balanceData.push(yearBalance);
        labels.push(year.toString());
      }
    }

    return { labels, balanceData };
  };

  const { labels, balanceData } = getBalanceData();

  const data = {
    labels,
    datasets: [
      {
        label: "Balance",
        data: balanceData,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
        text:
          selectedTimeFrame === 1
            ? "Balance Over Last 6 Months"
            : "Balance Over Last 6 Years",
      },
    },
  };

  return (
    <div className="mt-4">
      <Line data={data} options={options} />
    </div>
  );
};

export default TransactionsChart;
