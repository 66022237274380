import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import useCurrentUserStore from "../../../store/CurrentUserStore";
import TransactionsSettingsExport from "./TransactionsSettingsExport";
import TransactionsSettingsCategories from "./TransactionsSettingsCategories";
import TransactionsSettingsAccounts from "./TransactionsSettingsAccounts";
import TransactionsSettingsTags from "./TransactionsSettingsTags";
import TransactionsSettingsPreferences from "./TransactionsSettingsPreferences";
import { FaListAlt } from "react-icons/fa";
import {
  FaChevronDown,
  FaChevronUp,
  FaClipboardList,
  FaCreditCard,
  FaFileExport,
  FaTag,
} from "react-icons/fa6";

const TransactionsSettings = ({ onClose, onPreferencesChanged }) => {
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [accountsOpen, setAccountsOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);

  const { currentUser } = useCurrentUserStore();

  const handleCategoriesOpen = () => {
    setCategoriesOpen(!categoriesOpen);
    setAccountsOpen(false);
    setTagsOpen(false);
    setPreferencesOpen(false);
    setExportOpen(false);
  };

  const handleAccountsOpen = () => {
    setAccountsOpen(!accountsOpen);
    setCategoriesOpen(false);
    setTagsOpen(false);
    setPreferencesOpen(false);
    setExportOpen(false);
  };

  const handleTagsOpen = () => {
    setTagsOpen(!tagsOpen);
    setCategoriesOpen(false);
    setAccountsOpen(false);
    setPreferencesOpen(false);
    setExportOpen(false);
  };

  const handlePreferencesOpen = () => {
    setPreferencesOpen(!preferencesOpen);
    setCategoriesOpen(false);
    setAccountsOpen(false);
    setTagsOpen(false);
    setExportOpen(false);
  };

  const handleExportOpen = () => {
    setExportOpen(!exportOpen);
    setAccountsOpen(false);
    setCategoriesOpen(false);
    setTagsOpen(false);
    setPreferencesOpen(false);
  };

  return (
    <Modal
      onClose={onClose}
      title={"Transactions Settings"}
      showFootnote={categoriesOpen || accountsOpen || tagsOpen}
    >
      <div className="space-y-4 select-none">
        {/* Categories */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleCategoriesOpen}
          >
            <div className="flex items-center space-x-2">
              <FaListAlt className="text-lg" />
              <h2 className="text-lg font-semibold">Categories</h2>
            </div>
            <span>{categoriesOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {categoriesOpen && (
            <div className="mt-3">
              <TransactionsSettingsCategories currentUser={currentUser} />
            </div>
          )}
        </div>

        {/* Accounts */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleAccountsOpen}
          >
            <div className="flex items-center space-x-2">
              <FaCreditCard className="text-lg" />
              <h2 className="text-lg font-semibold">Accounts</h2>
            </div>
            <span>{accountsOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {accountsOpen && (
            <div className="mt-3">
              <TransactionsSettingsAccounts currentUser={currentUser} />
            </div>
          )}
        </div>

        {/* Tags */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleTagsOpen}
          >
            <div className="flex items-center space-x-2">
              <FaTag className="text-lg" />
              <h2 className="text-lg font-semibold">Tags</h2>
            </div>
            <span>{tagsOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {tagsOpen && (
            <div className="mt-3">
              <TransactionsSettingsTags currentUser={currentUser} />
            </div>
          )}
        </div>

        {/* Preferences */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handlePreferencesOpen}
          >
            <div className="flex items-center space-x-2">
              <FaClipboardList className="text-lg" />
              <h2 className="text-lg font-semibold">Preferences</h2>
            </div>
            <span>{preferencesOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {preferencesOpen && (
            <div className="mt-3">
              <TransactionsSettingsPreferences
                onPreferencesChanged={onPreferencesChanged}
              />
            </div>
          )}
        </div>

        {/* Export */}
        <div className="border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer text-black dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-400"
            onClick={handleExportOpen}
          >
            <div className="flex items-center space-x-2">
              <FaFileExport className="text-lg" />
              <h2 className="text-lg font-semibold">Export Transactions</h2>
            </div>
            <span>{exportOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {exportOpen && (
            <TransactionsSettingsExport
              currentUser={currentUser}
              currency={"EUR"}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TransactionsSettings;
