import { formatMoney } from "../../services/FormatService/FormatText";

const NetWorthProgress = ({ percentage, total, goal }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center mt-4">
      <h2 className="text-xl font-semibold text-center text-black dark:text-gray-300 mb-2">
        Net Worth Progress
      </h2>

      <div className="w-full max-w-[90vw] md:max-w-2xl bg-gray-200 dark:bg-gray-700 rounded-full h-6 mt-2 relative">
        <div
          className="bg-blue-500 h-6 rounded-full"
          style={{
            width: `${percentage}%`,
          }}
        />
        <div className="absolute inset-0 flex justify-center items-center text-white text-sm font-semibold">
          {percentage.toFixed(2)}%
        </div>
      </div>

      <p className="text-sm text-center text-gray-500 dark:text-gray-400 mt-2">
        {formatMoney(total, 2)} {" € / "} {formatMoney(goal, 2)} {" €"}
      </p>
    </div>
  );
};

export default NetWorthProgress;
